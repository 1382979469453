<template>
  <div>
    <WmtHeader :activeKey='activeKey'></WmtHeader>
    <div id="singleModule" style="height:auto;">
      <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/liveServerBg.png" alt="" class="topbg">
    </div>

    <div id="singleModule" style="background: #fff;height:auto;">
      <div class="moduleContainer" style="padding-top:47px;">
        <!-- 页面页眉 -->
        <div class="nav">
          <span class="navLast" @click="goNewsCenter()">直播服务</span>
          <i class="iconfont wemeet-gengduo navSplit"></i>
          <span class="navNow">您的位置</span>
        </div>

        <div class="videoBox">
          <video :src="nowItem.video" class="videoContainer" controls></video>
        </div>

        <div class="giftBox" @click="goLogin">
          <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/liveServer/liveGift.png" alt="礼物" class="gift">
        </div>

      </div>
    </div>

    <!-- 底部通栏 -->
    <WmtFooter></WmtFooter>
  </div>
</template>

<script>
import WmtHeader from '@/components/layout/wmtHeader.vue'
import WmtFooter from '@/components/layout/wmtFooter.vue'
import NewsBigItem from '../../components/newsItem/newsBigItem.vue'
import NewsItem from '@/components/newsItem/newsItem.vue'
export default {
  name: 'Detail',
  components: {
    WmtHeader,
    WmtFooter,
    NewsBigItem,
    NewsItem,
  },
  data() {
    return {
      activeKey: 'news',
      zhuboList: [
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/liveServer/zhubo1.png',
          video: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/liveServer/video1.mp4',
          name: '王宇坤',
          count: Math.floor(Math.random() * 5000),
          id: 1,
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/liveServer/zhubo2.png',
          video: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/liveServer/video2.mp4',
          name: '文儿',
          count: Math.floor(Math.random() * 5000),
          id: 2,
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/liveServer/zhubo3.png',
          video: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/liveServer/video3.mp4',
          name: '柠檬有点甜',
          count: Math.floor(Math.random() * 5000),
          id: 3,
        },
        {
          img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/liveServer/zhubo4.png',
          video: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/liveServer/video4.mp4',
          name: '陈晨',
          count: Math.floor(Math.random() * 5000),
          id: 4,
        },
      ],
      nowItem: {},
    }
  },
  mounted() {
    let id = this.$route.query.id || 1
    this.nowItem = this.zhuboList.filter((v) => v.id == id)[0]
  },
  methods: {
    goNewsCenter() {
      this.$router.push({ path: '/liveServer' })
    },
    goLogin() {
      this.$router.push({ path: '/login' })
    },
  },
}
</script>

<style lang="less" scoped>
.topbg {
  width: 100%;
  height: 210px;
}
.nav {
  .navLast {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    cursor: pointer;
  }
  .navSplit {
    font-size: 13px;
    color: #1c1c1c;
    margin: 0 10px;
    font-weight: 800;
  }
  .navNow {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #5abec1;
    cursor: pointer;
  }
}

.videoBox {
  margin-top: 90px;
  height: 600px;
  position: relative;
  .videoContainer {
    width: 800px;
    height: 520px;
    background: #d8d8d8;
    border-radius: 16px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    object-fit: fill;
  }
}

.giftBox {
  text-align: center;
  padding-bottom: 200px;
}
.gift {
  width: 800px;
  height: 98px;
  cursor: pointer;
}
</style>